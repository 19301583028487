import { FC, useEffect, useState } from "react";
import Question from "../common/interfaces/Question.interface";

interface PieChartProps {
    questionsAnsweredNonInfo: Question[]
}

const PieChart: FC<PieChartProps> = (props) => {

    const totalQuestion = props.questionsAnsweredNonInfo.length
    const [answeredYes, setAnsweredYes] = useState<number>(0)
    const [answeredNo, setAnsweredNo] = useState<number>(0)
    const [answeredDontKnow, setAnsweredDontKnow] = useState<number>(0)

    useEffect(() => {
        setAnsweredYes(props.questionsAnsweredNonInfo.filter((e) => e.selectedIndex === 0).length * 100 / totalQuestion)
        setAnsweredNo(props.questionsAnsweredNonInfo.filter((e) => e.selectedIndex === 1).length * 100 / totalQuestion)
        setAnsweredDontKnow(props.questionsAnsweredNonInfo.filter((e) => e.selectedIndex === 2).length * 100 / totalQuestion)

    }, [props.questionsAnsweredNonInfo,totalQuestion])

    return (
        <div className="risk_level_feedback_container" style={{ height: '70vh' }}>
            התפלגות תשובות ({totalQuestion} שאלות)
            <div className='pie-statistics-container'>
                <div style={{ background: `conic-gradient(rgb(255, 118, 118) 0% ${answeredYes}%, rgba(250, 176, 40, 0.801) 0% ${answeredYes + answeredNo}%, rgba(150, 176, 40, 0.801) 0% ${answeredNo + answeredYes + answeredDontKnow}%, rgba(50, 176, 40, 0.801) 0% ${100}% )` }} className='pie_statistics'></div>
            </div>

            <div className='color-box-container'>
                <div className='color-box-red'></div>
                תשובות "כן"
            </div>
            <div className='color-box-container'>
                <div className='color-box-yellow' ></div>
                תשובות "לא"
            </div>
            <div className='color-box-container'>
                <div className='color-box-light-green'></div>
                תשובות "לא ידוע/בבדיקה"
            </div>
            <div className='color-box-container'>
                <div className='color-box-dark-green'></div>
                תשובות "לא רלוונטי"
            </div>
        </div>
    );
}

export default PieChart;