import validateCondition from "./validateCondition";
import Question from "../interfaces/Question.interface";

function validateQuestion(question: any): question is Question {
    if (typeof question !== "object") {
        return false;
    }

    if (!Number.isInteger(question.questionNumber)) {
        return false;
    }

    if (typeof question.section !== "string") {
        return false;
    }

    if (typeof question.question !== "string") {
        return false;
    }

    if (!Array.isArray(question.answers) || question.answers.find((answer: any) => typeof answer !== "object" || typeof answer.score !== "number" || (answer.symbol && typeof answer.symbol !== "string"))) {
        return false;
    }

    if (question.selectedIndex !== undefined && (typeof question.selectedIndex !== "number" || question.selectedIndex >= question.answers.length)) {
        return false;
    }

    if (question.isUserQuestion && question.isUserQuestion !== true) {
        return false;
    }

    if (question.questionCondition && !validateCondition(question.questionCondition)) {
        return false;
    }

    if (question.sectionCondition && !validateCondition(question.sectionCondition)) {
        return false;
    }

    return true;
}

export default validateQuestion;