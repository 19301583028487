import React, { useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { TextareaAutosize } from '@mui/base';
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { useQuestionnaire } from "../stores/questionnaire.store";
import ReportAnswer, { userSavedInfoType } from "../components/ReportAnswer";
import Question from "../common/interfaces/Question.interface";
import { ReportQuestionType } from "../common/types/reportQuestions.type";
import ReportPageTranslation from "../translations/reportPage.translation";
import { historyRef } from "../App";
import ReportPopup from "../components/ReportPopup";

import '../scss/ReportPage.scss'

const ReportPage = () => {
    const questionnaireStore = useQuestionnaire();
    const { language } = questionnaireStore.userStore;
    const [userFeedBack, setUserFeedBack] = useState<userSavedInfoType[]>([])
    const [finishFeedBack, setFinishFeedBack] = useState<boolean>(false)
    const Arrow = questionnaireStore.userStore.direction === "rtl"
        ?
        KeyboardArrowRight
        :
        KeyboardArrowLeft;

    const saveTheValue = (quesId: string, value: string) => {
        const foundIndex = userFeedBack.findIndex(item => item.id === quesId);
        if (foundIndex !== -1) {
            const updatedData = [...userFeedBack];
            updatedData[foundIndex] = { ...updatedData[foundIndex], value: value };
            setUserFeedBack(updatedData);
        }
    }
    useAsyncEffect(async () => {
        await questionnaireStore.getReportData()
        setUserFeedBack(
            questionnaireStore.reportInfo.reduce((newArray: userSavedInfoType[], item: ReportQuestionType): userSavedInfoType[] => {
                const required = item.answers.length > 1 ? true : false;
                newArray.push({ id: item._id, value: "", required: required })
                return (newArray)
            }, [])
        )
    }, [])

    const goBackFunction = () => {
        historyRef.current?.push("risk-level")
    }

    const checkIfThereIsAnEmptyField = () => {
        const allFields = userFeedBack.every(ans => ans.value !== "" || ans.required === false)
        return !allFields;
    }

    const saveTheFormData = async () => {
        setFinishFeedBack(true)
        const mashovInfo: string[] = userFeedBack.reduce((newArray: string[], item: userSavedInfoType): string[] => {
            newArray.push(item.value)
            return (newArray)
        }, [])

        const reportNumber = questionnaireStore.riskLevelStore.questionnaireId
        questionnaireStore.riskLevelStore.fillTheReport()

        await axios.post("api/report-questions/save-report-in-sheet", { mashov: mashovInfo, reportNumber: reportNumber })
    }
    return (
        <div>
            <div className='questionnaire-title'>{ReportPageTranslation.TITLE[language]}</div>
            <div className="questionnaire_container" dir={questionnaireStore.userStore.direction}>

                <div className="questionnaire_inner_container">
                    <div className="question_header">
                        &nbsp;
                        <b>
                            {ReportPageTranslation.REPORT_HEADER[language]}
                        </b>
                    </div>
                    <div className="question_container" >
                        <>
                            {questionnaireStore.reportInfo.map((question, index: number) => {
                                return (
                                    <div key={index}>
                                        <span className="question">
                                            {question.questions[0].text}
                                        </span>
                                        <div style={{ paddingBottom: '5vh' }}>
                                            {
                                                question.answers.length > 1
                                                    ?
                                                    <ReportAnswer answers={question.answers} userFeedBack={userFeedBack} setUserFeedBack={setUserFeedBack} questionId={question._id} />
                                                    :
                                                    <TextareaAutosize minRows={3} className="input-field" placeholder={ReportPageTranslation.TYPE_HERE[language]} onChange={(event) => { saveTheValue(question._id, event.target.value) }} />
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <div className='back_and_next_buttons'>
                                <button disabled={checkIfThereIsAnEmptyField()} className="next_button" onClick={saveTheFormData} style={questionnaireStore.userStore.direction === "rtl" ? { "left": "6vw" } : { "right": "6vw" }}>
                                    {ReportPageTranslation.SEND[language]}
                                </button>

                                <div className='previous_button_container'>
                                    <button onClick={goBackFunction}>
                                        <Arrow className="previous_button" />
                                    </button>
                                    <span>חזור</span>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <ReportPopup isOpen={finishFeedBack} />
        </div>

    )
}

export default ReportPage