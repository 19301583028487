import { ReportAnswerType } from '../common/types/reportAnswer.type';
import '../scss/ReportAnswer.scss'

export interface userSavedInfoType {
    id: string,
    value: string,
    required:boolean,
}

interface FeedbackAnswerInterface {
    answers: ReportAnswerType[]
    userFeedBack: userSavedInfoType[]
    setUserFeedBack: (userFeedBack: userSavedInfoType[]) => void;
    questionId: string;
}

interface AnswerInputInterface {
    answer: ReportAnswerType,
    saveTheCorrectAnswer: (info: string) => void,
    info: string,
    selected?: boolean
}

const Answer = ({ answer, saveTheCorrectAnswer, info, selected }: AnswerInputInterface) => {
    return (
        <input
            type={"button"}
            min={1}
            placeholder={answer.content[0].text}
            value={answer ? answer.content[0].text : ''}
            className={`answer_button ${selected ? "active" : ""}`}
            onClick={() => { saveTheCorrectAnswer(info); }}
        />
    )
}

const ReportAnswer = ({ answers, userFeedBack, setUserFeedBack, questionId }: FeedbackAnswerInterface) => {

    const saveTheCorrectAnswer = (info: string) => {
        const foundIndex = userFeedBack.findIndex(item => item.id === questionId);
        const updatedData = [...userFeedBack];
        updatedData[foundIndex] = { ...updatedData[foundIndex], value: info };
        setUserFeedBack(updatedData);
    }
    const valueOfTheCorrectAnswer = userFeedBack.find(info => info.id === questionId)?.value
    const correctAnswerId: number = answers.findIndex(ans => ans.content[0].text === valueOfTheCorrectAnswer)
    return (
        <div className="feedback_answer_box" style={{ gap: `${2.5}%` }}>
            {answers.map((item: ReportAnswerType, index) => {
                return (
                    <Answer key={index} answer={item} saveTheCorrectAnswer={saveTheCorrectAnswer} info={item.content[0].text} selected={correctAnswerId === index} />
                )
            })}
        </div>
    )
}


export default ReportAnswer;