import { Contact } from "../interfaces/Contact.interface";
import { GetSectionsResponse } from "../interfaces/GetQuestionsResponse.interface";
import Question from "../interfaces/Question.interface";

interface SavedRiskLevel {
    feedbacks: string[];
    riskLevelName: string;
    questionnaireId: number;
    contacts: Contact[];
    conclusions: GetSectionsResponse[]

    questionsAnsweredNonInfo: Question[];
    proffesion: string;
    institution: string;
    age: string;
    theReportWasFilled: boolean
    submissionDate: Date
}

function validateRiskLevel(data: any): data is SavedRiskLevel {
    if (typeof data !== "object") {
        return false;
    }

    if (!isStringArray(data.feedbacks)) {
        return false;
    }

    if (typeof data.riskLevelName !== "string") {
        return false;
    }

    return true;
}

function isStringArray(arr: any): arr is string[] {
    return Array.isArray(arr) && arr.every(item => typeof item === "string");
}

export default validateRiskLevel;