import { action, autorun, computed, makeObservable, observable } from 'mobx';

import Condition from '../common/interfaces/Condition.interface';

import Question from '../common/interfaces/Question.interface';
import Section from '../common/interfaces/Section.interface';
import { QUESTIONNAIRE_STORAGE_NAME } from '../consts/keys';

import validateQuestions from '../common/functions/validateQuestions';


interface header {
    section?: string | null,
    parent?: string | null
}
class QuestionsStore {
    questions: Question[] = [];
    sectionCount = 4
    sectionIndex = 0
    parentSectionIndex = 0
    sectionQuestions: Question[] = []
    sections: Section[] = [];
    parentSections: Section[] = []
    districts: { value: string, id: number }[] = []
    district: string | null = null
    nextParentSections: Section[][] = []


    gotAll = false;

    constructor() {
        makeObservable(this, {
            questions: observable,
            sectionIndex: observable,
            parentSectionIndex: observable,
            sections: observable,
            parentSections: observable,
            nextParentSections: observable,
            sectionQuestions: observable,
            districts: observable,
            district: observable,
            currentSection: computed,
            currentHeader: computed,
            setAnswer: action,
            setAnswerValue: action,
            addData: action,
            setSectionsCount: action,
            resetQuestions: action,
            goBack: action,
            restore: action,
            start: action,
            setDistrict: action

        });

        this.setAnswer = this.setAnswer.bind(this);
        this.setAnswerValue = this.setAnswerValue.bind(this)
        this.goBack = this.goBack.bind(this);
    }

    get currentSection(): Section | null {
        if (this.sectionIndex >= this.sections.length) {
            return null;
        }
        return this.sections[this.sectionIndex];
    }

    get currentParentSection(): Section | null {
        if (this.parentSectionIndex >= this.parentSections.length) {
            return null;
        }
        return this.parentSections[this.parentSectionIndex];
    }

    get currentHeader(): header | null {
        const currentSection = this.currentSection;
        if (!currentSection) {
            return null;
        }

        const parentHeader = this.parentSections[this.parentSectionIndex]?.header || null

        return { section: currentSection.header || null, parent: parentHeader || null };


    }

    get canGoBack(): boolean {
        return this.sectionIndex !== 0;
    }
    get questionsAnsweredUnknown(): Question[] {
        const questions = this.questions.filter((e) => e.selectedIndex === 2 && ((e.answers[0].score >= 3) || e.questionNumber === 20))
        return questions
    }

    getQuestionsScore(): { questionNumber: number, section: string; score: number }[] {
        const data = this.questions.map((question, index) => ({
            questionNumber: question.questionNumber,
            section: question.section,
            score: this.getQuestionScore(index)
        })).filter(question => question.questionNumber > 0);

        return data;
    }


    getTotalScore() {
        let score = 0
        this.getQuestionsScore().forEach((e) => {
            score += e.score
        })

        return score
    }

    getQuestionScore(index: number): number {
        const question = this.questions[index];
        if (question.selectedIndex === undefined) {
            return 0;
        }

        return question.answers[question.selectedIndex].score;
    }

    setSectionsCount(count: number) {
        this.sectionCount = count;
    }

    setParentSections(parentSections: Section[]) {
        this.parentSections = [...parentSections, {
            name: 'SUMMARY',
            header: 'הערכה מסכמת'
        }];

        this.nextParentSections = []
    }

    setDistricts(districts: { value: string, id: number }[]) {
        this.districts = districts;
    }


    setDistrict(district: string | null): void {
        this.district = district;
    }


    resetQuestions(): void {
        this.questions = this.questions.filter(question => question.isUserQuestion);
        this.sections = [{ name: 'SYMBOL', header: 'מידע אישי' }]
        this.parentSections = []

    }

    start(userQuestions: Question[]): void {
        this.sectionIndex = 0;
        this.parentSectionIndex = 0
        this.questions = userQuestions;
        this.sections = [{ name: 'SYMBOL', header: 'מידע אישי' }]
        this.parentSections = []
        this.districts = []
        this.gotAll = false;

    }

    addData(questions: Question[], sectionName: string, sectionHeader: string | undefined, parentSection: string, gotAll: boolean, nextParentSections: Section[]
    ): void {
        //TODO i dont understand the difference

        this.questions = [...this.questions, ...questions];
        this.sections.push({
            name: sectionName,
            parent: parentSection,
            header: sectionHeader
        });
        this.gotAll = gotAll;
        if (nextParentSections.length > 0) {
            this.nextParentSections.push(
                nextParentSections
            );
        }


    }

    setAnswer(answerIndex: number | undefined, questionIndex: number): void {
        this.questions[questionIndex].selectedIndex = answerIndex;
    }

    //for input questions
    setAnswerValue(value: string, index: number, questionIndex: number): void {
        this.questions[questionIndex].answers[index].text = value
    }

    setSymbol(symbol: string, questionIndex: number): void {
        this.questions[questionIndex].answers[0].symbol = symbol
    }

    goBack(): void {

        if (this.currentSection?.parent !== this.sections[this.sectionIndex - 1].parent && this.parentSectionIndex !== 0) {
            this.parentSectionIndex -= 1
        }

        this.sectionIndex -= 1

        if (this.skipSection()) {
            this.goBack()
        }
    }

    skipQuestion(questionIndex: number): boolean {
        const currentQuestion = this.questions[questionIndex]

        if (!currentQuestion?.questionCondition) {
            return false;
        }
        const skip = !this.calculateCondition(currentQuestion.questionCondition);

        if (skip) { this.questions[questionIndex].selectedIndex = undefined; }
        return skip
    }

    skipSection(): boolean {
        let skip = false

        const currentQuestion = this.questions.filter((e) => e.section === this.currentSection?.name)[0]

        if (!currentQuestion?.sectionCondition) {
            return false;
        }

        skip = !this.calculateCondition(currentQuestion.sectionCondition);

        if (skip && this.currentSection?.name === 'CLASSIFICATION') {
            console.log(this.getTotalScore())
            skip = this.getTotalScore() < 50
        }

        if (skip) {
            this.questions.forEach((e, index) => {
                if (e.section === this.currentSection?.name) {
                    this.questions[index].selectedIndex = undefined
                }
            })
        }
        return skip
    }

    calculateCondition(condition: Condition): boolean {
        if (condition.and) {
            return this.calculateCondition(condition.and[0]) && this.calculateCondition(condition.and[1]);
        }

        if (condition.or) {
            return this.calculateCondition(condition.or[0]) || this.calculateCondition(condition.or[1]);
        }

        return this.calculateEqual(condition);
    }

    calculateEqual(condition: Condition): boolean {
        const [questionStr, answerStr] = condition.eq!;
        const answerIndex = parseInt(answerStr.replace("answer_", "")) - 1;
        const questionNumber = parseInt(questionStr.replace("q", ""));

        const question = this.questions.find(question => question.questionNumber === questionNumber);

        //TODO think more about it - no question
        if (!question) {
            return false;
        }

        return question.selectedIndex === answerIndex;
    }


    saveStatus(): void {
        if (this.questions.length === 0) {
            return;
        }

        const data = {
            questions: this.questions,
            sectionIndex: this.sectionIndex,
            parentSectionIndex: this.parentSectionIndex,
            sectionCount: this.sectionCount,
            // conclusions: this.conclusions,
            gotAll: this.gotAll,
            sections: this.sections,
            districts: this.districts,
            district: this.district,
            parentSections: this.parentSections,
            nextParentSections: this.nextParentSections
        };
        sessionStorage.setItem(QUESTIONNAIRE_STORAGE_NAME, JSON.stringify(data));
        sessionStorage.setItem("ts", JSON.stringify(new Date().getTime()));
    }

    deleteStorage(): void {
        sessionStorage.removeItem(QUESTIONNAIRE_STORAGE_NAME);
    }

    canRestore(): boolean {
        const strData = sessionStorage.getItem(QUESTIONNAIRE_STORAGE_NAME);
        if (!strData) {
            return false;
        }

        try {
            const data = JSON.parse(strData);
            const isValid = validateQuestions(data);
            if (!isValid) {
                return false;
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    restore(): void {
        const strData = sessionStorage.getItem(QUESTIONNAIRE_STORAGE_NAME);
        if (!strData) {
            return;
        }

        try {
            const data = JSON.parse(strData);
            this.sectionIndex = data.sectionIndex
            this.parentSectionIndex = data.parentSectionIndex
            this.questions = data.questions;
            this.gotAll = data.gotAll;
            this.sections = data.sections;
            this.parentSections = data.parentSections
            this.nextParentSections = data.nextParentSections
            this.districts = data.districts
            this.district = data.district
        } catch (err) {
        }
    }
}

export const questionsStore = new QuestionsStore();

autorun(() => questionsStore.saveStatus());