import { action, autorun, computed, makeObservable, observable } from 'mobx';

import parseSuggestion from '../common/functions/parseSuggestion';
import validateRiskLevel from '../common/functions/validateRiskLevel';
import { Contact } from '../common/interfaces/Contact.interface';
import { GetSectionsResponse } from '../common/interfaces/GetQuestionsResponse.interface';
import Question from '../common/interfaces/Question.interface';
import { ParsedSuggestion } from '../common/types/ParsedSuggestion.type';
import { RISK_LEVEL_STORAGE_NAME } from '../consts/keys';

class RiskLevelStore {
    riskLevelName: string | null = null;
    feedbacks: string[] | null = null;
    questionnaireId: number | null = null;
    contacts: Contact[] | null = null;
    conclusions: GetSectionsResponse[] = []
    questionsAnsweredNonInfo: Question[] = []
    proffesion: string = ''
    institution: string = ''
    age: string = ''
    theReportWasFilled: boolean = false;
    submissionDate:Date = new Date();

    constructor() {
        makeObservable(this, {
            setData: action,
            riskLevelName: observable,
            feedbacks: observable,
            conclusions: observable,
            questionnaireId: observable,

            questionsAnsweredNonInfo: observable,
            proffesion: observable,
            institution: observable,
            age: observable,

            restore: action,
            resetData: action,
            formattedFeedbacks: computed,
            feedbacksTitle: computed,
        });
    }

    get formattedFeedbacks(): ParsedSuggestion[] {
        return this.feedbacks!.slice(1).map(parseSuggestion);
    }


    get feedbacksTitle(): string {
        return this.feedbacks![0];
    }

    fillTheReport() {
        this.theReportWasFilled = true;
        const dataStr = JSON.stringify({
            riskLevelName: this.riskLevelName,
            feedbacks: this.feedbacks,
            questionnaireId: this.questionnaireId,
            contacts: this.contacts,
            conclusions: this.conclusions,

            questionsAnsweredNonInfo: this.questionsAnsweredNonInfo,
            proffesion: this.proffesion,
            institution: this.institution,
            age: this.age,
            theReportWasFilled: this.theReportWasFilled,
            submissionDate: this.submissionDate

        });
        sessionStorage.setItem(RISK_LEVEL_STORAGE_NAME, dataStr);
    }

    setData(feedbacks: string[], riskLevelName: string, questionnaireId: number, contacts: Contact[], conclusions: GetSectionsResponse[], questionsAnsweredNonInfo: Question[], proffesion: string, institution: string, age: string): void {
        this.feedbacks = feedbacks;
        this.riskLevelName = riskLevelName;
        this.questionnaireId = questionnaireId;
        this.contacts = contacts;
        this.conclusions = conclusions;

        this.questionsAnsweredNonInfo = questionsAnsweredNonInfo;
        this.proffesion = proffesion;
        this.institution = institution;
        this.age = age
        this.submissionDate = new Date()
    }

    resetData(): void {
        this.feedbacks = null;
        this.riskLevelName = null;
        this.questionnaireId = null;
        this.contacts = null;
        this.conclusions = []

        this.questionsAnsweredNonInfo = [];
        this.proffesion = '';
        this.institution = '';
        this.age = ''
    }

    saveStatus(): void {
        const dataStr = JSON.stringify({
            riskLevelName: this.riskLevelName,
            feedbacks: this.feedbacks,
            questionnaireId: this.questionnaireId,
            contacts: this.contacts,
            conclusions: this.conclusions,

            questionsAnsweredNonInfo: this.questionsAnsweredNonInfo,
            proffesion: this.proffesion,
            institution: this.institution,
            age: this.age,
            theReportWasFilled: this.theReportWasFilled,
            submissionDate: this.submissionDate
        });

        if (this.riskLevelName) {
            sessionStorage.setItem(RISK_LEVEL_STORAGE_NAME, dataStr);
        }
    }

    deleteStorage(): void {
        sessionStorage.removeItem(RISK_LEVEL_STORAGE_NAME);
        this.theReportWasFilled = false;
    }

    canRestore(): boolean {
        const dataStr = sessionStorage.getItem(RISK_LEVEL_STORAGE_NAME);
        if (!dataStr) {
            return false;
        }

        try {
            const data = JSON.parse(dataStr);
            if (!validateRiskLevel(data)) {
                return false;
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    restore(): void {
        const dataStr = sessionStorage.getItem(RISK_LEVEL_STORAGE_NAME);
        if (!dataStr) {
            return;
        }

        try {
            const data = JSON.parse(dataStr);
            if (!validateRiskLevel(data)) {
                return;
            }
            this.riskLevelName = data.riskLevelName;
            this.feedbacks = data.feedbacks;
            this.questionnaireId = data.questionnaireId;
            this.contacts = data.contacts;
            this.conclusions = data.conclusions;

            this.questionsAnsweredNonInfo = data.questionsAnsweredNonInfo;
            this.proffesion = data.proffesion;
            this.institution = data.proffesion;
            this.age = data.age
            this.theReportWasFilled = data.theReportWasFilled;
            this.submissionDate = data.submissionDate

        } catch (err) {

        }
    }
}

const riskLevelStore = new RiskLevelStore();
autorun(() => riskLevelStore.saveStatus());

export default riskLevelStore;
