import { FC, useEffect, useState } from "react";
import { GetSectionsResponse } from "../common/interfaces/GetQuestionsResponse.interface";
import Question from "../common/interfaces/Question.interface";
import { QuestionnaireStore } from "../stores/questionnaire.store";
import ContactInfo from "./contactInfo";
import PersonalInfo from "./PersonalInfo";

interface PdfProps {
    direction: "rtl" | "ltr",
    today: Date,
    questionnaireStore: QuestionnaireStore,
    conclusions: GetSectionsResponse[] | null | undefined,
    questions: Question[],
    questionsAnsweredNonInfo: Question[]
}


export const Pdf: FC<PdfProps> = ({ questionsAnsweredNonInfo, direction, today, questionnaireStore, conclusions, questions }) => {
    const [totalQuestion, setTotalQuestion] = useState<number>(1)
    const [answeredYes, setAnsweredYes] = useState<number>(0)
    const [answeredNo, setAnsweredNo] = useState<number>(0)
    const [answeredDontKnow, setAnsweredDontKnow] = useState<number>(0)
    const [topAnswer, setTopAnswer] = useState<number>(0)

    useEffect(() => {
        setTopAnswer(Math.max(answeredYes, answeredNo, answeredDontKnow, 100 - answeredYes - answeredNo - answeredDontKnow))
    }, [answeredYes, answeredNo, answeredDontKnow])

    useEffect(() => {
        setTotalQuestion(questionsAnsweredNonInfo.length)
        setAnsweredYes(questionsAnsweredNonInfo.filter((e) => e.selectedIndex === 0).length * 100 / totalQuestion)
        setAnsweredNo(questionsAnsweredNonInfo.filter((e) => e.selectedIndex === 1).length * 100 / totalQuestion)
        setAnsweredDontKnow(questionsAnsweredNonInfo.filter((e) => e.selectedIndex === 2).length * 100 / totalQuestion)

    }, [questionsAnsweredNonInfo, totalQuestion])
    return (
        <div id='pdf' className="risk_level_container" dir={direction}>
            <div>
                <div className='risk_level_header'>
                    <div className="header_date">{`${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`}</div>
                    <div className='header_icons'>
                    </div>
                </div>
                <div className='risk_level_title title_center'>הערכה מסכמת</div>
                <PersonalInfo questions={questions} />
                {questionnaireStore.riskLevelStore.feedbacks ? '' : ''}
            </div>

            {questionnaireStore.riskLevelStore.feedbacks ?
                <div>
                    {/* <div className='risk_level_title'>תוצאות</div> */}
                    <div className="risk_level_feedback_container" style={{ height: '15vh' }}>
                        הערכת סכנה
                        <div className='evaluation'>{questionnaireStore.riskLevelStore.feedbacks[0]}</div>
                    </div>
                    <div className="risk_level_feedback_container">
                        המלצות לדרכי פעולה
                        <div className='risk_level_feedback_info'>{questionnaireStore.riskLevelStore.feedbacks[1]}</div>
                    </div>

                    <div className='risk_level_title'>תשובות שאלון</div>
                    <div className="risk_level_feedback_container feedback_answer_divide">
                        התפלגות תשובות ({totalQuestion} שאלות)
                        <div className='pie-statistics-container'>
                            <div className="bar_graph">
                                <div className="bar" style={{ height: `${answeredYes / topAnswer * 100}%`, backgroundColor: 'rgb(255, 118, 118)' }}></div>
                                <div className="bar" style={{ height: `${answeredNo / topAnswer * 100}%`, backgroundColor: 'rgba(250, 176, 40, 0.8)' }}></div>
                                <div className="bar" style={{ height: `${answeredDontKnow / topAnswer * 100}%`, backgroundColor: 'rgba(150, 176, 40, 0.8)' }}></div>
                                <div className="bar" style={{ height: `${(100 - answeredYes - answeredNo - answeredDontKnow) / topAnswer * 100}%`, backgroundColor: 'rgba(50, 176, 40, 0.8)' }}></div>

                            </div>
                        </div>

                        <div className='color-box-container color-box-container-up'>
                            <div className='color-box-red'></div>
                            תשובות "כן"
                        </div>
                        <div className='color-box-container color-box-container-up'>
                            <div className='color-box-yellow' ></div>
                            תשובות "לא"
                        </div>
                        <div className='color-box-container color-box-container-up'>
                            <div className='color-box-light-green'></div>
                            תשובות "לא ידוע/בבדיקה"
                        </div>
                        <div className='color-box-container color-box-container-up'>
                            <div className='color-box-dark-green'></div>
                            תשובות "לא רלוונטי"
                        </div>
                    </div>

                    <div className="risk_level_feedback_container">
                        פירוט התשובות
                        <div>
                            <p className='risk_level_feedback_container_header'>מצבי סכנה</p>
                            <ul className='risk_level_feedback_info no_list_style'>
                                {conclusions && conclusions.map((e, index) => {
                                    if (e.section === 'DANGER_CONCLUSION') {
                                        return <li key={index} className='li-with-bullet'>
                                            <div className='marker'>&nbsp;</div>
                                            {e.conclusions[0].text}
                                        </li>
                                    }
                                    return ''
                                })}
                            </ul>
                        </div>
                        <div>
                            <p className='risk_level_feedback_container_header'> סממנים לקיומה של פגיעה</p>
                            <ul className='risk_level_feedback_info'>
                                {conclusions && conclusions.map((e, index) => {
                                    if (e.section === 'ASSUALT_CONCLUSION') {
                                        return <li key={index} className='li-with-bullet'>
                                            <div className='marker'>&nbsp;</div>
                                            {e.conclusions[0].text}
                                        </li>
                                    }
                                    return ''
                                })}
                            </ul>
                        </div>
                        <div>
                            <p className='risk_level_feedback_container_header'>  מגבירי סיכון</p>
                            <ul className='risk_level_feedback_info'>
                                {conclusions && conclusions.map((e, index) => {
                                    if (e.section === 'RISK_CONCLUSION') {
                                        return <li key={index} className='li-with-bullet' >
                                            <div className='marker'>&nbsp;</div>
                                            {e.conclusions[0].text}
                                        </li>
                                    }
                                    return ''
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                : ''
            }


        </div>
    );
}

export default Pdf;