import axios from 'axios';
import GetRiskLevelResponse from '../../../common/interfaces/GetRiskLevelResponse';


export async function getRiskLevel(
    values: any
) {
    const { questions, district } = values;

    return await axios.post<GetRiskLevelResponse>('/api/risk-level/get-risk-level', {
        questions,
        district
    });
}