import 'react-app-polyfill/ie9';
import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router } from "react-router-dom";

import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';
//font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//rtl material
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { QuestionnaireProvider } from './stores/questionnaire.store';

import { queryClient } from './lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

library.add(fas)



const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
    <QueryClientProvider client={queryClient}>
    {/* @ts-expect-error */}
    <QuestionnaireProvider>
        <StylesProvider jss={jss}>
            <Router>
                <App />
            </Router>
        </StylesProvider>
    </QuestionnaireProvider>
    </QueryClientProvider>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
