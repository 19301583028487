import createTranslation from "./createTranslation";

const ReportPageTranslation = createTranslation({
    SEND: {
        he: "שלח",
        en: "send",
        ar: "يرسل",
        am: "ላክ",
        ru: "Отправлять"
    },
    REPORT_HEADER: {
        he: "משוב",
        en: "report",
        ar: "تعليق",
        am: "አስተያየት",
        ru: "обратная связь"
    },
    TITLE: {
        he: "שאלון איתותים",
        en: "Suicidal Risk Diagnosis Questionnaire",
        ar: "استبيان تشخيص مخاطر الانتحار",
        am: "ራስን የማጥፋት አደጋ ምርመራ መጠይቅ",
        ru: "Анкета для диагностики суицидального риска"
    },
    TYPE_HERE:{
        he: "הקלד כאן",
        en: "type here",
        ar: "أكتب هنا",
        am: "እዚህ ይተይቡ",
        ru: "введите здесь"
    },
    POPUP_TITLE:{
        he: "תודה שהקדשת מזמנך לענות על השאלון, האם ברצונך לחזור לדף הסיכום?",
        en: "Thank you for taking the time to answer the questionnaire, do you want to return to the summary page or finish?",
        ar: "شكرا لأخذ الوقت الكافي للإجابة على الاستبيان ، هل تريد العودة إلى صفحة الملخص أو الانتهاء؟",
        am: "መጠይቁን ለመመለስ ጊዜ ስለወሰዱ እናመሰግናለን፣ ወደ ማጠቃለያ ገጹ መመለስ ወይም መጨረስ ይፈልጋሉ?",
        ru: "Спасибо, что нашли время, чтобы ответить на вопросник, вы хотите вернуться на страницу резюме или закончить?"
    },
    RETURN:{
        he: "חזרה",
        en: "Back to the summary page",
        ar: "العودة إلى صفحة الملخص",
        am: "ወደ ማጠቃለያ ገጽ ተመለስ",
        ru: "Назад к сводной странице"
    },
    FINISHED:{
        he:"סיום",
        en: "to finish",
        ar: "انهاء",
        am: "ጨርስ",
        ru: "заканчивать"
    }
});

export default ReportPageTranslation;