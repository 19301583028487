import { FC, useState } from 'react';
import { observer } from 'mobx-react';

import { historyRef } from "../App";
import { useQuestionnaire } from '../stores/questionnaire.store';
import GenericPopup from '../components/GenericPopup';

import returnPopupTranslation from '../translations/returnPopup.translation';

import "../scss/ReturnPopup.scss";

const ReturnPopup: FC = () => {
    const questionnaireStore = useQuestionnaire();
    const { language } = questionnaireStore.userStore;

    const [showPopup, setShowPopup] = useState(questionnaireStore.isAuthenticated && window.location.pathname !== "/center");
    const handleReturn = () => {
        setShowPopup(false);
        questionnaireStore.restore();
    }

    const handleNew = () => {
        questionnaireStore.deleteStorage();
        historyRef.current?.push("");
        setShowPopup(false);
    }

    if (!showPopup) {
        return null;
    }

    return (
        <GenericPopup isOpen={showPopup} title={returnPopupTranslation.QUESTION[language]}>
            <div className="return_popup_button_container">
                <button className='report_popup_finish' onClick={handleReturn}>{returnPopupTranslation.RETURN[language]}</button>
                <button  className='report_popup_return_button' onClick={handleNew}>{returnPopupTranslation.RESTART[language]}</button>
            </div>
        </GenericPopup>
    );
}

export default observer(ReturnPopup);