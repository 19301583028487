import type { FC , PropsWithChildren} from 'react';
import { observer } from 'mobx-react';

import { useQuestionnaire } from '../stores/questionnaire.store';

import "../scss/GenericPopup.scss";

export interface GenericPopupProps extends PropsWithChildren {
    isOpen: boolean;
    title: string;
    onClose?: () => void
    titleSize?: string
    popupWidth?: string
}

const GenericPopup: FC<GenericPopupProps> = ({ isOpen, title, children, onClose, titleSize, popupWidth }) => {
    const questionnaireStore = useQuestionnaire();
    const { direction } = questionnaireStore.userStore;

    if (!isOpen) {
        return null;
    }

    return (
        <div className="generic_popup_page_container" dir={direction}>
            <div className="generic_popup_container" style={{ width: popupWidth ? popupWidth : '' }}>
                <div className='popup-close-button' onClick={onClose}>+</div>
                <div style={{overflow:'scroll'}}>
                    <span className="generic_popup_text_container" style={{ fontSize: titleSize ? titleSize : '' }}>
                        {title}
                    </span>
                    {
                        children
                    }
                </div>
            </div>
        </div>
    );
}

export default observer(GenericPopup);