import { FC, useEffect, useState } from "react";
import Question from "../common/interfaces/Question.interface";
import { useQuestionnaire } from "../stores/questionnaire.store";
import { questionsStore } from "../stores/questions.store";

interface PersonalInfoProps {
    questions: Question[]
}

const PersonalInfo: FC<PersonalInfoProps> = (props) => {
    const questionnaireStore = useQuestionnaire();

    const [isName, setIsName] = useState<boolean>(false)
    const [name, setName] = useState<string | undefined>()

    useEffect(() => {
        setIsName(props.questions.find((e) => e.questionNumber === 78)?.selectedIndex === 0)
        setName(props.questions.find((e) => e.questionNumber === 79)?.answers[0].text)
    }, [props.questions])

    let age = questionnaireStore.riskLevelStore.age
    if (age === '1') {
        age = 'שנה'
    } else if (age === '2') {
        age = 'שנתיים'
    }

    return (
        <div className='risk_level_info'>
            <div><span className='risk-level-bold'>מספר דו"ח:</span> {questionnaireStore.riskLevelStore.questionnaireId}</div>
            <div><span className='risk-level-bold'>פרטי הילד:</span> {isName ? `${name},` : ''} גיל {age}, רשות מקומית {questionsStore.district}</div>
            <div><span className='risk-level-bold'>פרטי ממלא השאלון:</span> {questionnaireStore.riskLevelStore.institution}, {questionnaireStore.riskLevelStore.proffesion}</div>
        </div>
    );
}

export default PersonalInfo;