import { FC, useState } from "react";
import Question from "../common/interfaces/Question.interface";

import GenericPopup from "./GenericPopup";

interface UnknownQuestionsProps {
    unknownQuestions: Question[]
}

const UnknownQuestions: FC<UnknownQuestionsProps> = (props) => {

    const [open, setOpen] = useState(false)
    const sign = ' >'

    return (
        <div style={{ fontSize: '13px', fontWeight: '700', marginBottom: '5vh' }}>
            <div  >
                * שים לב כי לא השבת על מספר שאלות שעלולות להעיד על מצב סכנה ממשית בו נמצא הילד.
                <br />
                יש לקחת את הממלצה לפעולה על רקע היעדר מידע זה, ולנסות לבררו ככל הניתן
            </div>
            <div onClick={() => setOpen(true)} style={{ borderBottom: 'solid 1px #05668d', color: '#05668d', width: 'fit-content' }}> {` לצפייה בשאלות הרלוונטיות ${sign}`}</div>
            <GenericPopup isOpen={open} popupWidth='500px' titleSize='16px' title='שאלות שעלולות להעיד על סיכון ממשי ולא נענו' onClose={() => setOpen(false)}>
                <ul style={{ paddingInlineStart: '30px' }}>
                    {props.unknownQuestions.map((e, index) => {
                        return (
                            <li key={index} style={{ paddingBottom: '7px' }}>{e.question}</li>
                        )
                    })}
                </ul>
            </GenericPopup>
        </div>
    );
}

export default UnknownQuestions;