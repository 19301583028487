import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router';

import { useQuestionnaire } from '../stores/questionnaire.store';
import ErrorPopup from '../components/ErrorPopup';

import "../scss/RiskLevel.scss";
import { useAsyncEffect } from '@hilma/tools';
import Question from '../common/interfaces/Question.interface';
import GenericPopup from '../components/GenericPopup';
import { GetSectionsResponse } from '../common/interfaces/GetQuestionsResponse.interface';
import ContactInfo from '../components/contactInfo';
import { CSVLink } from "react-csv";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Pdf from '../components/pdf';
import PersonalInfo from '../components/PersonalInfo';
import PieChart from '../components/PieChart';
import UnknownQuestions from '../components/UnknownQuestions';
import { historyRef } from '../App';
import RiskLevelTranslation from '../translations/riskLevel.translation';
import { CircularProgress } from '@material-ui/core';


const RiskLevel: FC = () => {
    const questionnaireStore = useQuestionnaire();
    const { direction, language, } = questionnaireStore.userStore;
    const { theReportWasFilled } = questionnaireStore.riskLevelStore
    const today = new Date()
    const [questionsAnsweredNonInfo, setQuestionsAnsweredNonInfo] = useState<Question[]>([])
    const [questions, setQuestions] = useState<Question[]>([])
    const [conclusions, setConclusions] = useState<GetSectionsResponse[] | null>()
    const [isOpen, setIsOpen] = useState(false)
    const unknownQuestions = questionnaireStore.questionsStore.questionsAnsweredUnknown



    useEffect(() => {
        setQuestionsAnsweredNonInfo(questionnaireStore.riskLevelStore.questionsAnsweredNonInfo)
        setQuestions(JSON.parse(JSON.stringify(questionnaireStore.questionsStore.questions)))
    }, [questionnaireStore.questionsStore.questions, questionnaireStore.riskLevelStore.questionsAnsweredNonInfo])


    useAsyncEffect(() => {
        const filteredConclusions = questionnaireStore.riskLevelStore.conclusions.filter((e) =>
            questions.find((question) => question.questionNumber === e.questionNumber)?.selectedIndex === 0
        )
        setConclusions(filteredConclusions)

    }, [questionnaireStore.riskLevelStore.conclusions, questions])

    const escapeCsvValue = (value: string) => {
        value = value.replace(/"/g, '""');
        return value;
    }

    const csvData = questionsAnsweredNonInfo?.map((e) => {
        return [escapeCsvValue(e.question), e.answers[e.selectedIndex!].text]
    })


    if (!questionnaireStore.userStore.isAuthenticated || !questionnaireStore.questionsStore.questions?.length) {
        return <Redirect to="" />;
    }

    if (!questionnaireStore.riskLevelStore.riskLevelName) {
        return (
            <div className="risk_level_container" >
                <ErrorPopup isOpen={questionnaireStore.errorOccurred} onClick={questionnaireStore.getRiskLevel} />
            </div>
        );
    }

    const DownloadReport = async function () {

        const input: HTMLElement | null = document.getElementById('pdf');


        input && html2canvas(input)
            .then((canvas) => {

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF(
                    {
                        unit: "px",
                        format: 'a4'
                    }
                );

                pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), input.clientHeight);
                for (let page = 1; page < input.clientHeight / pdf.internal.pageSize.getHeight(); page++) {
                    pdf.addPage()
                    pdf.addImage(imgData, 'JPEG', 0, -page * pdf.internal.pageSize.getHeight(), pdf.internal.pageSize.getWidth(), input.clientHeight);

                }
                pdf.save(`הערכה מסכמת.pdf`);
            })
            ;
    }

    const goToReport = async () => {
        await questionnaireStore.getReportData();
        historyRef.current?.push("report");
    }
    
    console.log("render")
    return (
        <>
            <div className="risk_level_container" dir={direction}>
                <GenericPopup onClose={() => { setIsOpen(false) }} isOpen={isOpen} title='אנו מזכירים על חובת שמירת סודיות' >
                    <p className='popup-header'>
                        יש לשמור את המסמך בהתאם לכללי אבטחת מידע ושמירת פרטיות</p>
                    <div className='popup-body'>
                        <button className='popup-button' onClick={() => { setIsOpen(false); DownloadReport() }}><div>הורדת סיכום</div> <img className='popup-img' src='/images/pdfIcon.png' alt='הורדת סיכום לקובץ pdf' /></button>
                        <CSVLink className='popup-excel' data={csvData} filename={"signal-answers.xls"}>הורדת תשובות  <img className='popup-img' src='/images/xls.png' alt='הורדת תשובות לקובץ אקסל' /></CSVLink>
                    </div>
                </GenericPopup>
                <div className='risk_level_header'>
                    <div>{`${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`}</div>
                    <div className='header_icons'>
                        <img className='header_icon' src='/images/download.png' alt='הורדה' onClick={() => { setIsOpen(true) }} />
                    </div>
                </div>
                <div className='risk_level_title title_center'>הערכה מסכמת</div>
                <PersonalInfo questions={questions} />
                {questionnaireStore.riskLevelStore.feedbacks ?
                    <>
                        <div>
                            {/* <div className='risk_level_title'>תוצאות</div> */}
                            <div className="risk_level_feedback_container" style={{ height: '15vh' }}>
                                הערכת סכנה
                                <div className='evaluation'>{questionnaireStore.riskLevelStore.feedbacks[0]}</div>
                            </div>
                            <div className="risk_level_feedback_container">
                                המלצות לדרכי פעולה
                                <div className='risk_level_feedback_info'>{questionnaireStore.riskLevelStore.feedbacks[1]}</div>
                            </div>
                        </div>
                        {unknownQuestions?.length !== 0 && questionnaireStore.riskLevelStore.riskLevelName !== 'רמת מסוכנות גבוהה' ?
                            <UnknownQuestions unknownQuestions={unknownQuestions} />
                            : ''
                        }
                        <div className='risk_level_feedback_container'>
                            <ContactInfo />
                        </div>

                        <div className='risk_level_title'>תשובות שאלון</div>
                        <PieChart questionsAnsweredNonInfo={questionsAnsweredNonInfo} />

                        <div className="risk_level_feedback_container">
                            פירוט התשובות
                            <div>
                                <p className='risk_level_feedback_container_header'>מצבי סכנה</p>
                                <ul className='risk_level_feedback_info'>
                                    {conclusions && conclusions?.map((e, index) => {
                                        if (e.section === 'DANGER_CONCLUSION') {
                                            return <li key={index}>{e.conclusions[0].text}</li>
                                        }
                                        return ''
                                    })}
                                </ul>
                            </div>
                            <div>
                                <p className='risk_level_feedback_container_header'> סממנים לקיומה של פגיעה</p>
                                <ul className='risk_level_feedback_info'>
                                    {conclusions && conclusions?.map((e, index) => {
                                        if (e.section === 'ASSUALT_CONCLUSION') {
                                            return <li key={index}>{e.conclusions[0].text}</li>
                                        }
                                        return ''
                                    })}
                                </ul>
                            </div>
                            <div>
                                <p className='risk_level_feedback_container_header'>  מגבירי סיכון</p>
                                <ul className='risk_level_feedback_info'>
                                    {conclusions && conclusions?.map((e, index) => {
                                        if (e.section === 'RISK_CONCLUSION') {
                                            return <li key={index}>{e.conclusions[0].text}</li>
                                        }
                                        return ''
                                    })}
                                </ul>
                            </div>

                        </div>
                    </>
                    : <CircularProgress />
                }
                {theReportWasFilled === false &&
                    <div className="feedback_button">
                        <p className='feedback_text'>
                            {RiskLevelTranslation.FILL_FEEDBACK[language]}
                        </p>
                        <button onClick={goToReport} >
                            {RiskLevelTranslation.FILL_FEEDBACK_BUTTON[language]}
                        </button>
                    </div>
                }
                <Pdf direction={direction} questionsAnsweredNonInfo={questionsAnsweredNonInfo} today={today} questionnaireStore={questionnaireStore} conclusions={conclusions} questions={questions} />
            </div >
        </>
    );
}

export default observer(RiskLevel);