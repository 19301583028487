import { FC, useState } from "react";
import { observer } from "mobx-react";

import { useQuestionnaire } from "../stores/questionnaire.store";

import HomeInnerBar from "../components/HomeInnerBar";
import HomeTopBar from "../components/HomeTopBar";
import HomeText from '../components/HomeText';

import HomeTranslation from '../translations/home.translation';
import ErrorPopup from "../components/ErrorPopup";
import Keywords from "../components/Keywords";

import "../scss/Home.scss";

const Home: FC = () => {

    const questionnaireStore = useQuestionnaire();
    const { language } = questionnaireStore.userStore;
    const [instructions, setInstructions] = useState(false)
    const [showError, setShowError] = useState(false);

    const handleStart = () => {
        questionnaireStore.createUser()
            .then(() => {
                setShowError(false);
            })
            .catch(() => {
                setShowError(true);
            });
    }
    const width = window.innerWidth

    return (
        <>
            <div className="home_top_bar_container" style={{ width: '100%', justifyContent: 'unset' }}>
                <img src="/images/welfare.png" alt="משרד הרווחה והביטחון החברתי" title="משרד הרווחה והביטחון החברתי" className="welfare_image" />
                <img src="/images/dangered-children.png" alt="" title="" />
                {width < 850 && <img alt='logo' className='logo' src='/icon.png' />}

            </div>
            <div className="home_container" dir={questionnaireStore.userStore.direction}>
                <HomeInnerBar
                    title={HomeTranslation.TITLE[language]}
                />
                {instructions ?
                    <HomeText
                        buttonText={HomeTranslation.START[language]}
                        onStart={handleStart}
                    >
                        הפריטים בשאלון זה מחולקים לשלושה חלקים:
                        <br />
                        1. זיהוי מצבי סכנה מיידיים וממשיים
                        <br />
                        2. זיהוי סממנים העלולים להצביע על פגיעה בילד
                        <br />
                        3. זיהוי גורמים מגבירי סיכון להתעללות והזנחה של ילדים ובני נוער
                        <br />
                        <br />
                        הנחיות למילוי השאלון:
                        <ul style={{ marginBlockStart: 'unset' }}>
                            <li>חשוב לענות על כל השאלות, על מנת שתוצאות השאלון יוכלו לתת תמונה ראשונית מהימנה ומדויקת ככל שניתן.</li>
                            <li>במידה ומידע מסוים אינו מצוי ברשותך, מומלץ לעשות מאמץ סביר להשיגו מהגורמים הרלוונטיים. </li>
                            <li>במידה ושאלה מסוימת אינה רלוונטית לגיל הילד.ה ו/או השלב ההתפתחותי בו הוא מצוי, יש לסמן ‘לא רלוונטי‘.</li>
                            <li>במידה ואינך יודע את התשובה ואין ביכולתך להשלים את המידע החסר או שהוא מצוי בבדיקה , יש לסמן ‘לא ידוע/בבדיקה‘.</li>
                            <li>במידה והינך ממלא את השאלון על ילד הגדל במשפחת אומנה, יש להשיב על השאלות בהתייחס למשפחת האומנה.</li>
                            <li>במונח 'אחראי/ים' הכוונה להורה/ים או מי שעליו האחריות לדאוג לצרכיו של הילד</li>
                        </ul>
                        בסיום מילוי השאלון יתקבל סיווג לאחת משלושת רמות סכנה והמלצה מתאימה לפעולה.
                        <br />
                        <br />
                        <b>יודגש, כי ההכוונה המתקבלת בסיום מילוי השאלון רלוונטית למועד מילויו ומחייבת את הגורם המקצועי שמילא אותו להמשיך לעקוב אחר מצבו של הילד ולגלות ערנות לשינויים בהתנהגותו, מצבו הפיזי והרגשי ולצרכיו.</b>

                    </HomeText>

                    :
                    <HomeText
                        buttonText={HomeTranslation.CONTINUE[language]}
                        onStart={() => { setInstructions(true) }}
                    >
                        שלום!
                        <br />
                        <br />
                        אם הגעת לכאן כנראה שאת.ה  מודאג.ת לגבי ילד שאת.ה מכיר.ה  וחושש.ת כי הוא חווה מצבי סיכון בעוצמה ובתדירות גבוהה.  בדיוק לשם מטרה זו, פיתחנו את שאלון האיתותים –כלי עזר המיועד לך, איש.אשת המקצוע הפוגש.ת ילדים ונוער בעבודתו.ה ומטרתו לסייע באיתור והערכה של מצבי סכנה המעוררים חשש ממשי לחייהם, בריאותם והישרדותם עקב פגיעה, הזנחה או התעללות. בסוף המילוי תוצע לך הכוונה באשר לדחיפות ולאופן בו יש לפעול לשם העמקת הערכת המצב ובחינת אופני ההתערבות הטיפולית וההגנתית הנדרשים.                        <br /><br />
                        <b>חשוב להדגיש כי השאלון מהווה כלי תומך החלטה ואינו מחליף את ההליך הרגיל של התייעצות ודיווח.</b>
                    </HomeText>
                }
            </div>
            <HomeTopBar />
            <ErrorPopup isOpen={showError} onClick={handleStart} />
            <Keywords />
        </>
    );
}

export default observer(Home);