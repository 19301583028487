import createTranslation from "./createTranslation";

const HomeTranslation = createTranslation({
    START: {
        he: "הבא",
        en: "start",
        ar: "بداية",
        am: "እንጀምር",
        ru: "Начало"
    },
    CONTINUE: {
        he: "המשך",
        en: "continue",
        ar: "بداية",
        am: "እንጀምር",
        ru: "Начало"
    },
    TITLE: {
        he: "שאלון איתותים לילדים ובני נוער",
        en: "Suicidal Risk Diagnosis Questionnaire",
        ar: "استبيان تشخيص مخاطر الانتحار",
        am: "ራስን የማጥፋት አደጋ ምርመራ መጠይቅ",
        ru: "Анкета для диагностики суицидального риска"
    },
    TEXT: {
        he: [

            "שלום רב,",
            "'שאלון איתותים' הוא כלי עזר המיועד לך, איש.אשת המקצוע הפוגש.ת ילדים ונוער בעבודתו.ה ומטרתו לסייע באיתור והערכה של מצבי סכנה המעוררים חשש ממשי לחייהם, בריאותם והישרדותם עקב פגיעה, הזנחה או התעללות. בסוף המילוי תוצע לך הכוונה באשר לדחיפות ולאופן בו יש לפעול לשם העמקת הערכת המצב ובחינת אופני ההתערבות הטיפולית וההגנתית הנדרשים.",
            'חשוב להדגיש כי השאלון מהווה כלי תומך החלטה ואינו מחליף את ההליך הרגיל של התייעצות ודיווח.'
        ],
        en: ["<en>שלום רב...<en>"],
        ar: ["<ar>שלום רב...<ar>"],
        am: ["<am>שלום רב...<am>"],
        ru: ["<ru>שלום רב...<ru>"]
    },
    INSTRUCTIONS: {
        he: [
            "השאלון מורכב משלושה חלקים תוך הבחנה בין הסממנים העלולים להצביע על פגיעה בילד לבין גורמים המוכרים בספרות המקצועית ובפרקטיקה כמגבירים את הסיכון להתעללות והזנחה של ילדים ונוער. על מנת שתוצאותיו יוכלו לתת תמונה ראשונית מהימנה ומדויקת ככל שניתן, חשוב לענות על כל השאלות המופיעות בו. במידה ומידע מסוים אינו מצוי ברשותך, מומלץ לעשות מאמץ סביר להשיגו מהגורמים הרלוונטיים. במידה ושאלה מסוימת אינה רלוונטית לגיל הילד.ה ו/או השלב ההתפתחותי בו הוא מצוי, יש לסמן 'לא רלוונטי'. במידה ואינך יודע את התשובה ואין ביכולתך להשלים את המידע החסר או שהוא מצוי בבדיקה , יש לסמן 'לא ידוע/בבדיקה'.",
            "יודגש, כי ההכוונה המתקבלת בסיום מילוי השאלון רלוונטית למועד מילויו ומחייבת את הגורם המקצועי שמילא אותו להמשיך לעקוב אחר מצבו של הילד ולגלות ערנות לשינויים בהתנהגותו, מצבו הפיזי והרגשי ולצרכיו.",
            "*במידה והינך ממלא את השאלון על ילד הגדל במשפחת אומנה, יש להשיב על השאלות בו בהתייחס למשפחת האומנה.",
            "*במונח 'אחראי/ים' הכוונה להורה/ים או מי שעליו האחריות לדאוג לצרכיו של הילד",
            "לתחילת המילוי לחץ הבא"
        ],
        en: ["<en>שלום רב...<en>"],
        ar: ["<ar>שלום רב...<ar>"],
        am: ["<am>שלום רב...<am>"],
        ru: ["<ru>שלום רב...<ru>"]
    }
});

export default HomeTranslation;