import Question from "../common/interfaces/Question.interface";
import LanguageType from "../common/types/language.type";
import StartTranslation from '../translations/start.translation';

// pre questions
function getUserQuestions(language: LanguageType): Question[] {
    return [
        {
            isUserQuestion: true,                       
            questionNumber: 1,
            section: "SYMBOL",
            parentSection:'DANGER_CONCLUSIONS',
            question: StartTranslation.AGE_GROUP[language],
            answers: [
                {
                    score: 0,
                    symbol: 'child',
                    type: 'select',
                    placeholder: 'הכנס את גיל המטופל'
                },
            ]
        },
        {
            isUserQuestion: true,
            questionNumber: 2,
            section: "SYMBOL",
            parentSection:'DANGER_CONCLUSIONS',
            question: StartTranslation.GENDER[language],
            answers: [
                {
                    score: 0,
                    text: StartTranslation.MALE[language],
                    symbol: "male"
                },
                {
                    score: 0,
                    text: StartTranslation.FEMALE[language],
                    symbol: "female"
                },
                {
                    score: 0,
                    text: StartTranslation.OTHER[language],
                    symbol: "other"
                },
            ]
        },

    ];
}


// export getUserConclusions;
export const hebrewUserQuestions = getUserQuestions("he");
export default getUserQuestions;