import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useQuestionnaire } from '../stores/questionnaire.store';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, TextField } from '@material-ui/core';

export interface AnswerButtonProps {
    text: string | undefined,
    selected: boolean,
    input: string | undefined,
    onClick: () => void,
    index: number,
    questionIndex: number,
    placeholder: string | undefined,
    answersLength: number
}


const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    textField: {
        width: '100%',
        fontSize: '18px',
        fontFamily: ' Heebo-Regular',
        color: '#756e67',
        position: 'relative',
        alignItems: 'center',
        height: 'fit-content',
        border: 'none',
        marginTop: '0px',
        marginBottom: '0px',
        padding: '4px 0px'
    }
}));

const AnswerButton: FC<AnswerButtonProps> = ({ text, selected, onClick, input, index, questionIndex, placeholder, answersLength }) => {

    const questionnaireStore = useQuestionnaire();
    const [value, setValue] = useState<string | undefined>(text)
    const { districts, district } = questionnaireStore.questionsStore
    const [options, setOptions] = useState<{ value: string, id: number }[]>([])
    const classes = useStyles();

    useEffect(() => {
        setOptions(districts)
    }, [districts])

    function handleChange(e: any) {
        setValue(e.target.value)
    }

    function handleClick(e: any) {
        if (e.target.value !== '') {
            if (questionnaireStore.questionsStore.questions[questionIndex].isUserQuestion) {
                questionnaireStore.setAnswerValue(e.target.value, index, questionIndex, `${JSON.parse(e.target.value) > 11 ? 'teen' : 'child'}`)
            }
            onClick()
            questionnaireStore.setAnswerValue(e.target.value, index, questionIndex)
        } else {
            questionnaireStore.questionsStore.questions[questionIndex].selectedIndex = undefined
            questionnaireStore.setAnswerValue(placeholder ? placeholder : '', index, questionIndex)
        }

    }
    function handleDistrictClick(e: any) {
        if (options.some(option => option.value === e.target.value)) {

            onClick()
            questionnaireStore.questionsStore.setDistrict(e.target.value)
            questionnaireStore.setAnswerValue(e.target.value, index, questionIndex)
        }
    }
    const width = window.innerWidth
    return (
        input ?
            input === 'select' ?
                <div className='select-container'>
                    <select style={{ width: `${100 / answersLength}%` }} value={value} onChange={(event) => { handleChange(event); handleClick(event) }} className={`answer_button ${selected ? "active" : ""} age-select`}>
                        <option value='' disabled selected>בחר גיל</option>
                        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'].map((e, index) => {
                            return (
                                <option key={index} value={e}>{e}</option>
                            )
                        })}
                    </select>
                    <img className='select-img' src='/images/down-arrow.svg' alt='select' />
                </div>
                : input === 'autoComplete' ?
                    <div className='container-for-autocomplete'>
                        <Autocomplete
                            id="tags-standard"
                            disablePortal={true}
                            className={classes.textField}
                            options={options.length > 0 ? options : districts}
                            getOptionLabel={(option) => option.value}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={(district && district !== '') ? district : placeholder}
                                    margin="normal"
                                    value={district ?? placeholder}
                                    className={classes.textField}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                                    onSelect={(event) => { handleChange(event); handleDistrictClick(event) }}

                                />
                            )}
                        />
                    </div>
                    : <input style={{ width: width > 850 ? `${90 / answersLength}%` : '100%' }} type={input} min={1} placeholder={placeholder} value={value ? value : ''} className={`answer_button ${selected ? "active" : ""}`} onChange={(event) => { handleChange(event); handleClick(event) }} />
            :
            <button style={{ width: width > 850 ? `${90 / answersLength}%` : '100%' }} className={`answer_button ${selected ? "active" : ""}`} onClick={onClick} >
                {text}
                < div className='check_icon_div' >
                    {
                        < FontAwesomeIcon icon={["fas", "check"]} className='checkIcon' />
                    }
                </div >
            </button >
    );
}

export default AnswerButton;

