import { action, makeObservable, observable } from 'mobx';
import axios from 'axios';

import { createMobXContext } from '@hilma/tools';

import { GetQuestionsResponse, GetStartQuestionsResponse } from '../common/interfaces/GetQuestionsResponse.interface';
import { historyRef } from '../App';
import { questionsStore } from './questions.store';
import userStore from './user.store';
import riskLevelStore from './riskLevel.store';

import getUserQuestions, { hebrewUserQuestions } from '../consts/getUserQuestions';
import { STOP_TIME_STORAGE_NAME } from '../consts/keys';
import { QUESTIONNAIRE_MAX_TIME } from '../consts/questionnaireMaxTime';
import { ReportQuestionType } from '../common/types/reportQuestions.type';
import { getRiskLevel } from '../lib/react-query/api';

export class QuestionnaireStore {
    userStore = userStore;
    questionsStore = questionsStore;
    riskLevelStore = riskLevelStore;
    timeout: ReturnType<typeof setTimeout> | null = null;
    reportInfo: ReportQuestionType[] = [];
    errorOccurred = false;


    constructor() {
        makeObservable(this, {
            userStore: observable,
            questionsStore: observable,
            riskLevelStore: observable,
            errorOccurred: observable,
            nextStep: action,
            nextSection: action,
            goBack: action,
            setErrorOccurred: action,
        });

        this.nextStep = this.nextStep.bind(this);
        this.setAnswer = this.setAnswer.bind(this);
        this.setAnswerValue = this.setAnswerValue.bind(this);

        this.goBack = this.goBack.bind(this);
        this.createUser = this.createUser.bind(this);
        this.tryAgain = this.tryAgain.bind(this);
        this.getRiskLevel = this.getRiskLevel.bind(this);
    }

    get isAuthenticated(): boolean {
        return this.userStore.isAuthenticated && this.canRestore();
    }

    setErrorOccurred(value: boolean): void {
        this.errorOccurred = value;
    }

    async getReportData(){
        const data = await axios.get('api/report-questions/bring-the-report-data')
        this.reportInfo = data.data
    }

    async createUser(): Promise<void> {
        this.deleteStorage();
        this.userStore.saveStatus();

        // FACT
        const hebrewFirstQuestion = hebrewUserQuestions[0];

        const data = {
            language: this.userStore.language,
            firstQuestionNumber: hebrewFirstQuestion.questionNumber
        };

        try {
            await axios.post("/api/user", data);
            // const res = await axios.get("/api/question/get-parent-sections");
            this.questionsStore.start(getUserQuestions(this.userStore.language));
            this.userStore.startQuestionnnaire();
            historyRef.current?.push("/questionnaire");
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async startQuestionnaire(): Promise<void> {
        // this.userStore.saveStatus();
        this.setErrorOccurred(false);
        this.questionsStore.resetQuestions();
        const userQuestions = this.questionsStore.questions.filter(question => question.isUserQuestion && question.selectedIndex !== undefined && question.answers[question.selectedIndex].symbol !== "none");
        const userData = userQuestions.map(question => question.answers[question.selectedIndex!].symbol!);
        //FACT
        const lastQuestion = userQuestions[userQuestions.length - 1];
        const hebrewLastQuestion = hebrewUserQuestions.find(question => question.questionNumber === lastQuestion.questionNumber);

        const data = {
            userData,
            lastQuestionNumber: lastQuestion.questionNumber,
            lastQuestionText: lastQuestion.question,
            lastAnswer: hebrewLastQuestion?.answers[lastQuestion.selectedIndex!].text
        };

        try {
            const res = await axios.post<GetStartQuestionsResponse>("/api/question/start-questionnaire", data);
            axios.get("api/fact-unfinished/start-the-questionnaire")
            const { startQuestions, count, districts, parentSections } = res.data;
            const sectionsCount = count;
            this.questionsStore.setParentSections(parentSections)
            this.questionsStore.setSectionsCount(sectionsCount);
            this.questionsStore.setDistricts(districts)

            this.questionsStore.addData(startQuestions.questions, startQuestions.section, startQuestions.sectionHeader, startQuestions.parentSection, startQuestions.done, startQuestions.nextParentSections);
        } catch (err) {
            console.log(err);
            this.setErrorOccurred(true);
            this.goBack();
        }
    }

    async getNextQuestions(): Promise<void> {
        this.setErrorOccurred(false);
        try {
            const res = await axios.get<GetQuestionsResponse>("/api/question/next-section");
            this.questionsStore.addData(res.data.questions, res.data.section, res.data.sectionHeader, res.data.parentSection, res.data.done, res.data.nextParentSections);
        } catch (err) {
            console.log(err);
            this.setErrorOccurred(true);
        }
    }

    setAnswer(answer: number | undefined, questionIndex: number): void {

        this.questionsStore.setAnswer(answer, questionIndex);

        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }

    setAnswerValue(answer: string, index: number, questionIndex: number, symbol?: string): void {
        this.questionsStore.setAnswerValue(answer, index, questionIndex);
        symbol && this.questionsStore.setSymbol(symbol, questionIndex)
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }


    goBack(): void {
        this.questionsStore.goBack();
        console.log(this.timeout, 'timeout')
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    async nextStep() {
        if (this.userStore.questionnaireStopped) {
            return;
        }
        if (this.questionsStore.gotAll && this.questionsStore.sectionIndex === this.questionsStore.sections.length - 1) {
            // results in popup opening in client and then riskLevel()
            return true;
        }

        this.nextQuestion();
    }

    async getRiskLevel(): Promise<void> {
        axios.get("api/fact-unfinished/finish-the-questionnaire")
        this.setErrorOccurred(false);

        // make sure no old data is shown
        this.riskLevelStore.resetData();
        historyRef.current?.push("risk-level");

        const questionsScore = this.questionsStore.getQuestionsScore();

        // FACT
        // const currentQuestion = this.questionsStore.currentQuestion?.selectedIndex !== undefined ? this.questionsStore.currentQuestion : this.questionsStore.questions[this.questionsStore.findLastQuestionIndex()];
        const data = {
            questions: questionsScore,
            district: questionnaireStore.questionsStore.district
        };


        try {
            const res = await getRiskLevel(data);

            //@ts-ignore
            const startTime = window.sessionStorage.getItem(STOP_TIME_STORAGE_NAME) - QUESTIONNAIRE_MAX_TIME

            const warehouseInfo = {
                questions: this.questionsStore.questions,
                evaluation: res.data.feedbacks[0],
                score: this.questionsStore.getTotalScore(),
                duration: (Date.now() - startTime) / 1000

            }
            const warehouseRes = await axios.post("/api/fact-questionnaire/add-to-data-warehouse", warehouseInfo)
            this.riskLevelStore.setData(res.data.feedbacks, res.data.name, warehouseRes.data.questionnaireId, res.data.contacts, res.data.conclusions, warehouseRes.data.questionsAnsweredNonInfo, warehouseRes.data.proffesion, warehouseRes.data.institution, warehouseRes.data.age);
        } catch (err) {
            console.log(err);
            this.setErrorOccurred(true);
        }
    }

    async nextQuestion(): Promise<void> {

        if (this.questionsStore.currentSection === this.questionsStore.sections[0]) {
            await this.startQuestionnaire();

        }

        else if (!this.questionsStore.gotAll && this.questionsStore.sectionIndex >= this.questionsStore.sections.length - 1) {
            await this.getNextQuestions();

        }


        if (this.questionsStore.canGoBack && this.questionsStore.currentSection?.parent !== this.questionsStore.sections[this.questionsStore.sectionIndex + 1].parent) {
            this.questionsStore.parentSectionIndex++;

        }

        this.questionsStore.sectionIndex++;
        if (this.questionsStore.skipSection()) {
            this.nextQuestion()
        }
    }

    nextSection(): void {
        // for (let i = this.questionsStore.questionIndex + 1; i < this.questionsStore.questions.length; i++) {
        //     if (this.questionsStore.questions[i].section !== this.questionsStore.questions[this.questionsStore.questionIndex].section) {
        //         this.questionsStore.questionIndex = i;
        //         return;
        //     }
        // }

        //! if the first question of the next section has a condition - it will not check it
        // this.questionsStore.questionIndex = this.questionsStore.questions.length;

        // this.getNextQuestions()
        //     .then(() => this.questionsStore.sendFact());
    }

    //after error
    tryAgain(): void {
        // const lastIndex = this.questionsStore.findLastQuestionIndex();
        // const lastQuestion = this.questionsStore.questions[lastIndex];
        // if (lastQuestion?.isUserQuestion) {
        //     this.startQuestionnaire();
        // } else {
        //     this.getNextQuestions();
        // }
    }

    canRestore(): boolean {
        //check session storage timestamp, if 5 min past then cant restore

        const timestamp = sessionStorage.getItem("ts");
        if (!timestamp) {
            return false;
        }
        let ts = parseInt(timestamp);
        if (Number.isNaN(ts)) {
            sessionStorage.removeItem("ts");
            return false;
        }
        const timeDiff = 1000 * 60 * 5; //5 min
        if (new Date().getTime() - ts > timeDiff) {
            return false;
        }
        if (!this.userStore.canRestore()) {
            return false;
        }
        if (!this.questionsStore.canRestore()) {
            return false;
        }
        return true;
    }

    restore(): void {
        const canRestoreUser = this.userStore.canRestore();
        const canRestoreQuestions = this.questionsStore.canRestore();
        const canRestoreRiskLevel = this.riskLevelStore.canRestore();

        if (canRestoreRiskLevel && canRestoreQuestions && canRestoreUser) {
            this.userStore.restore();
            this.questionsStore.restore();
            this.riskLevelStore.restore();
            historyRef.current?.push("/risk-level");
            return;
        }

        if (canRestoreQuestions && canRestoreUser) {
            this.userStore.restore();
            this.questionsStore.restore();
            historyRef.current?.push("/questionnaire");
            return;
        }

        this.deleteStorage();
        historyRef.current?.push("");
    }

    deleteStorage(): void {
        this.userStore.logout();
        this.userStore.deleteStorage();
        this.questionsStore.deleteStorage();
        this.riskLevelStore.deleteStorage();
        sessionStorage.removeItem("ts");
    }
}

export const questionnaireStore = new QuestionnaireStore();
(window as any).store = questionnaireStore;
export const [QuestionnaireContext, QuestionnaireProvider, useQuestionnaire] = createMobXContext(questionnaireStore);