import { FC } from "react";

import '../scss/HomeInnerBar.scss';

interface HomeInnerBarProps {
    title: string;
}
const width = window.innerWidth

const HomeInnerBar: FC<HomeInnerBarProps> = ({ title }) => {
    return (
        <div className="home_inner_bar_container">
            {width >= 850 && <img alt='logo' className='logo' src='/icon.png' />}
            <h1 className="title">
                {title}
            </h1>
            {/* <LanguageSelect /> */}
        </div>
    );
}

export default HomeInnerBar;