import type { FC , PropsWithChildren} from "react";
import "../scss/HomeText.scss";

interface HomeTextProps extends PropsWithChildren {
    buttonText: string;
    onStart: () => void;
}

const HomeText: FC<HomeTextProps> = ({ buttonText, onStart, children }) => {
    return (
        <div className="home_text_container">
            {children}
            <div className="button_container">
                <button onClick={onStart}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
}

export default HomeText;