import validateQuestion from "./validateQuestion";

function validateQuestions(data: any): boolean {
    if (typeof data !== "object") {
        return false
    }
    if (!Number.isInteger(data.sectionCount)) {
        return false;
    }
    if (!Number.isInteger(data.sectionIndex)) {
        return false;
    }
    if (!Number.isInteger(data.parentSectionIndex)) {
        return false;
    }
    if (typeof data.gotAll !== "boolean") {
        return false;
    }
    if (!Array.isArray(data.sections) || data.sections.find((section: any) => typeof section !== "object" || typeof section.name !== "string" || (section.header !== null && typeof section.header !== "string"))) {
        return false;
    }
    if (!Array.isArray(data.parentSections) || data.parentSections.find((section: any) => typeof section !== "object" || typeof section.name !== "string" || (section.header !== null && typeof section.header !== "string"))) {
        return false;
    }
    if (!Array.isArray(data.questions) || data.questions.find((question: any) => !validateQuestion(question))) {
        return false;
    }
    return true;
}

export default validateQuestions;