import type { FC } from "react";
import { CircularProgress } from "@material-ui/core";

import "../scss/Loading.scss";

const LoadingPage: FC = () => {
    return (
        <div className="loading_page" style={{ zIndex: 10,  width: '100%', height: '100vh',position:"fixed", backgroundColor:"white",}}>
            <CircularProgress size={100} />
        </div>
    );
}

export default LoadingPage;