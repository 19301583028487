import { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import { useQuestionnaire } from '../stores/questionnaire.store';
import AnswerBox from "../components/AnswerBox";
import ProgressBar from "../components/ProgressBar";
import QuestionnaireTranslation from '../translations/questionnaire.translation';
import "../scss/Questionnaire.scss";
import StopQuestionnairePopup from '../components/StopQuestionnairePopup';
import ErrorPopup from '../components/ErrorPopup';
import Question from '../common/interfaces/Question.interface';
import FinishedPopup from '../components/FinishedPopup';
import { useGetRiskLevel } from '../lib/react-query/hooks/useRiskLevel';

const Questionnaire: FC = () => {

    const questionnaireStore = useQuestionnaire();
    const questions = JSON.parse(JSON.stringify(questionnaireStore.questionsStore.questions))
    const currentSection = questionnaireStore.questionsStore.currentSection;
    const ref = useRef<HTMLDivElement>(null);
    const [popupOpen, setPopupOpen] = useState(false)

    const {
        mutate: getRiskLevel,
    } = useGetRiskLevel();

    useEffect(() => {
        ref.current && ref.current.scrollIntoView();
    }, [currentSection])

    const header = questionnaireStore.questionsStore.currentHeader;

    const { language } = questionnaireStore.userStore;
    const disabled = questions.filter((e: Question, index: number) => e.section === currentSection?.name && e.selectedIndex === undefined && !questionnaireStore.questionsStore.skipQuestion(index)).length !== 0
    const showLoading = currentSection === null;

    async function nextStep() {
        const isFinished = await questionnaireStore.nextStep()
        isFinished === true && setPopupOpen(true)
    }

    if (!questionnaireStore.userStore.isAuthenticated || !questions.length) {
        return <Redirect to="/" />;
    }

    const Arrow = questionnaireStore.userStore.direction === "rtl"
        ?
        KeyboardArrowRight
        :
        KeyboardArrowLeft;

    return (
        <div>
            <div className='questionnaire-title' ref={ref}>שאלון איתותים</div>
            <div className="questionnaire_container" dir={questionnaireStore.userStore.direction}>
                <div className="questionnaire_inner_container">
                    {currentSection?.name === 'CLASSIFICATION' ? '' :
                        <div className="question_header">
                            {header?.parent !== header?.section && header?.parent !== null ? `${header?.parent} - ` : ''}
                            &nbsp;
                            <b>
                                {header?.section}
                            </b>
                        </div>
                    }
                    <div className="question_container" >
                        {
                            !showLoading && currentSection
                                ?
                                <>

                                    {questions.map((e: Question, index: number) => {

                                        // checks if the question meets condition
                                        if (e.section === currentSection?.name && !questionnaireStore.questionsStore.skipQuestion(index)) {
                                            return (
                                                <div key={index}>
                                                    <span className="question">
                                                        {e.question}
                                                    </span>
                                                    <div style={{ paddingBottom: '5vh' }}>
                                                        <AnswerBox questionIndex={index} selected={e.selectedIndex} setSelected={questionnaireStore.setAnswer} answers={e.answers} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return ''
                                    })}
                                    <div className='back_and_next_buttons'>
                                        <button disabled={disabled} className="next_button" onClick={nextStep} style={questionnaireStore.userStore.direction === "rtl" ? { "left": "6vw" } : { "right": "6vw" }}>
                                            {QuestionnaireTranslation.NEXT_BUTTON[language]}
                                        </button>
                                        {
                                            questionnaireStore.questionsStore.canGoBack
                                                ?
                                                <div className='previous_button_container'>
                                                    <button onClick={questionnaireStore.goBack}>
                                                        <Arrow className="previous_button" />
                                                    </button>
                                                    <span>חזור</span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </>
                                :
                                null

                        }
                    </div>

                </div>
                {questionnaireStore.questionsStore.canGoBack ?
                    <div className="progress_container">
                        <ProgressBar max={questionnaireStore.questionsStore.sectionCount} progress={questionnaireStore.questionsStore.parentSectionIndex} />
                    </div> : ''
                }
                <StopQuestionnairePopup />
                <ErrorPopup isOpen={questionnaireStore.errorOccurred && !currentSection} onClick={questionnaireStore.tryAgain} />
                <FinishedPopup isOpen={popupOpen} onClick={getRiskLevel} onClose={() => { setPopupOpen(false) }} />
            </div >
        </div >
    );
}

export default observer(Questionnaire);