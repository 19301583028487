import { FC } from 'react';

import '../scss/HomeTopBar.scss';

const HomeTopBar: FC = () => {
    return (
        <div className="home_top_bar_container">
            <img src="/images/hilma.png" alt='הילמה- הייטק למען החברה' title='הילמה- הייטק למען החברה' />
            <img src="/images/medina-misradim.png" alt="משרדי הממשלה" title="משרדי הממשלה" />
            <img src="/images/joint.png" alt="ג'וינט אלכא" title="ג'וינט אלכא" />
            <img src="/images/suicide-research-institution.png" alt="המרכז לחקר האובדנות והכאב הנפשי" title="המרכז לחקר האובדנות והכאב הנפשי" />

        </div>
    );
}

export default HomeTopBar;