import { FC } from "react";
import { observer } from "mobx-react";

import GenericPopup from "./GenericPopup";

import "../scss/ErrorPopup.scss";

interface FinishedPopupProps {
    isOpen: boolean;
    onClose: () => void;
    onClick: () => void;
}

const FinishedPopup: FC<FinishedPopupProps> = ({ isOpen, onClick, onClose }) => {


    const text = 'להערכה המסכמת'

    return (
        <GenericPopup onClose={onClose} isOpen={isOpen} title={'תודה שענית על השאלון!'}>
            <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
            <p style={{textAlign:'center'}}>אנו מקווים שהשאלון יסייע לך.</p>
            <button style={{width:'50%', marginTop:'7%'}}  className="report_popup_finish" onClick={onClick}>{text}</button>
            </div>
        </GenericPopup>

    );
}

export default observer(FinishedPopup);