import { FC, useEffect, useState } from 'react';
import "../scss/contactInfo.scss";
import { useQuestionnaire } from '../stores/questionnaire.store';

const ContactInfo = () => {

    const questionnaireStore = useQuestionnaire();
    const { contacts } = questionnaireStore.riskLevelStore;
    const [searchText, setSearchText] = useState<string>('')
    const [changingContArr, setChangingContArr] = useState(contacts ?? [])

    useEffect(() => {
        const checkAgainst = new RegExp(searchText, 'i')
        contacts && setChangingContArr((searchText === '' || !searchText) ? contacts : contacts.filter((row) => checkAgainst.test(row.city)))
    }, [searchText, contacts])

    return (
        <div>
            <div className='contact_info_header'>
                <div className='title_for_phone'>יצירת קשר עם המחלקה לשירותים חברתיים</div>
              <div className='search'>
                    <input
                        type='text'
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                    >
                    </input>
                    <img src='/images/search-interface-symbol.svg' alt='icon indicating search' className='search_bar_img' />
                </div>
            </div>
            {window.innerWidth > 850 ?
                <div className='contact_info_table'>
                    <div className='column_in_table'>שם ישוב</div>
                    <div className='column_in_table'>טלפון</div>
                    <div className='column_in_table'>דוא"ל</div>
                </div> :
                <></>

            }
            <div className={`scroll_box_container`} >
                {changingContArr.map((row, index) => {
                    const phoneArr = row.phone.split(',')
                    return (

                        window.innerWidth > 850 ?
                            <div className='row_container' key={index}>
                                <h3>{row.email}</h3>
                                <h3>{row.phone}</h3>
                                <h3>{row.city}</h3>
                            </div> :

                            <div style={{ borderBottom: index === changingContArr.length - 1 ? 'none' : 'rgba(0, 0, 0, 0.2) solid 1px' }} className='row_container' key={index}>
                                <div className='contact_info_row'>
                                    {row.email}
                                    <img alt='email icon' className='email_img' src='/images/email.svg' />
                                </div>
                                <div className='contact_info_row'>
                                    {phoneArr.map((e, index) => {
                                        return (
                                            <a key={index} style={{ textAlign: 'right' }} href={`tel:${e}`}>,{e}</a>

                                        )
                                    })

                                    }
                                    <img alt='phone icon' className='phone_img' src='/images/phone.svg' />
                                </div>
                                <div><b>{row.city}</b></div>
                            </div>
                    )
                })}
            </div>
        </div>
    )

}


export default ContactInfo