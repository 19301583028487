import createTranslation from "./createTranslation";

const StartTranslation = createTranslation({
    START: {
        he: "התחל",
        en: "start",
        ar: "بداية",
        am: "እንጀምር",
        ru: "Начало"
    },
    TITLE: {
        he: "שאלון אובדנות",
        en: "Suicide Questionnaire",
        ar: "استبيان الانتحار",
        am: "ራስን የማጥፋት መጠይቅ",
        ru: "Анкета самоубийства"
    },
    FOR_WHOM: {
        he: "עבור מי את/ה ממלא/ת את השאלון",
        en: "For whom do you fill out the questionnaire",
        ar: "لمن تملأ الاستبيان",
        am: "መጠይቁን ለማን ይሙሉ?",
        ru: "Для кого вы заполняете анкету"
    },
    AGE_GROUP: {
        he: "מהו גיל הילד/ה",
        en: "age",
        ar: "الفئة العمرية",
        am: "እድሜ ክልል",
        ru: "возрастная группа"
    },
    GENDER: {
        he: "מגדר של הילד/ה",
        en: "gender",
        am: "ፆታ",
        ar: "جنس",
        ru: "Пол"
    },
    PREFERRED_PRONOUNS: {
        he: "באיזה מגדר תרצו שנשתמש?",
        en: "What are your prefered pronouns",
        am: "<am>What are your prefered pronouns<am>",
        ar: "<ar>What are your prefered pronouns<ar>",
        ru: "<ru>What are your prefered pronouns<ru>"
    },
    MALE: {
        he: "זכר",
        en: "male",
        ar: "<ar>male<ar>",
        am: "<am>male<am",
        ru: "<ru>male<ru>"
    },
    FEMALE: {
        he: "נקבה",
        en: "female",
        ar: "<ar>female<ar>",
        am: "<am>female<am",
        ru: "<ru>female<ru>"
    },
    SELF: {
        he: "עבורי",
        en: "for me",
        ar: "<ar>for me<ar>",
        am: "<am>for me<am",
        ru: "<ru>for me<ru>"
    },
    OTHER: {
        he: "אחר",
        en: "other",
        ar: "<ar>other<ar>",
        am: "<am>other<am",
        ru: "<ru>other<ru>"
    },
    // AGE_INPUT_PLACEHOLDER: {
    //     he: "גיל המטופל",
    //     en: "enter the patient's age",
    //     ar: "<ar>enter the patient's age<ar>",
    //     am: "<am>enter the patient's age<am",
    //     ru: "<ru>enter the patient's age<ru>"
    // },
    UNDER_14: {
        he: "מתחת ל-14",
        en: "under 14",
        ar: "<ar>under 14<ar>",
        am: "<am>under 14<am",
        ru: "<ru>under 14<ru>"
    },
    FROM_15_TO_18: {
        he: "15 עד 18",
        en: "15 to 18",
        ar: "<ar>15 to 18<ar>",
        am: "<am>15 to 18<am",
        ru: "<ru>15 to 18<ru>"
    },
    PLUS_18: {
        he: "18 עד 55",
        en: "18 to 55",
        ar: "<ar>18 to 55<ar>",
        am: "<am>18 to 55<am",
        ru: "<ru>18 to 55<ru>"
    },
    PLUS_55: {
        he: "מעל 55",
        en: "plus 55",
        ar: "<ar>plus 55<ar>",
        am: "<am>plus 55<am",
        ru: "<ru>plus 55<ru>"
    },
    TRANSGENDER: {
        he: "טרנסג'נדר",
        en: "transgender",
        ar: "<ar>transgender<ar>",
        am: "<am>transgender<am>",
        ru: "<ru>transgender<ru>"
    },
    OTHER_GENDER: {
        he: "אחר",
        en: "other",
        ar: "<ar>other<ar>",
        am: "<am>other<am>",
        ru: "<ru>other<ru>"
    }
});

export default StartTranslation;