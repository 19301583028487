
import { useMutation } from '@tanstack/react-query';

import { useQuestionnaire } from '../../../stores/questionnaire.store';

//todo change this to useQuery
export const useGetRiskLevel = () => {
    const questionnaireStore = useQuestionnaire();

    function handleError(error: any) {
        if (error.status && error.status === 403) {
            console.log('error 403');
        }
    }

    return useMutation({
        mutationKey: ['getRiskLevel'],
        mutationFn: questionnaireStore.getRiskLevel,
        onError: handleError,
    });
};
