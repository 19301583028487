import { FC } from 'react';
import { observer } from 'mobx-react';

import { useQuestionnaire } from '../stores/questionnaire.store';

import "../scss/GenericPopup.scss";
import ReportPageTranslation from '../translations/reportPage.translation';
import { historyRef } from '../App';

export interface GenericPopupProps {
    isOpen: boolean;
}

const ReportPopup: FC<GenericPopupProps> = ({ isOpen }) => {
    const questionnaireStore = useQuestionnaire();
    const { direction } = questionnaireStore.userStore;
    const { language } = questionnaireStore.userStore;
    

    if (!isOpen) {
        return null;
    }

    return (
        <div className="generic_popup_page_container" dir={direction}>
            <div className="generic_popup_container">
                <div style={{ overflow: 'scroll' }}>
                    <span className="generic_popup_text_container" >
                        {ReportPageTranslation.POPUP_TITLE[language]}
                    </span>
                    <div className="return_popup_button_container">
                        <button className='report_popup_finish' onClick={() => { questionnaireStore.deleteStorage(); historyRef.current?.push("") }}>{ReportPageTranslation.FINISHED[language]}</button>
                        <button className='report_popup_return_button' onClick={() => { historyRef.current?.push("risk-level") }}>{ReportPageTranslation.RETURN[language]}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ReportPopup);