import { FC } from 'react';
import AnswerButton from "./AnswerButton";
import "../scss/Answers.scss";
import Answer from '../common/interfaces/Answer.interface';

export interface AnswerBoxProps {
    answers: Array<Answer>;
    selected: number | undefined;
    setSelected: (newNumber: number, questionIndex: number) => void;
    questionIndex: number;
}

const AnswerBox: FC<AnswerBoxProps> = (props) => {
    const { answers, selected, setSelected, questionIndex } = props;
    return (
        <div className="answer_box" style={{ gap: `${2.5}%`}}>
            {answers.map((answer, index) => {
                return <AnswerButton answersLength={answers.length} key={index} text={answer.text} placeholder={answer.placeholder} input={answer.type} selected={selected === index} onClick={() => { setSelected(index, questionIndex) }} index={index} questionIndex={questionIndex} />
            })}
        </div>
    );
}

export default AnswerBox;