import { FC } from 'react';
import "../scss/ProgressBar.scss";
import { useQuestionnaire } from '../stores/questionnaire.store';

export interface ProgressBarProps {
    max: number;
    progress: number;
}



const ProgressBar: FC<ProgressBarProps> = (props) => {


    const questionnaireStore = useQuestionnaire();
    const { max, progress } = props;
    const nextParentSections = questionnaireStore.questionsStore.nextParentSections[progress]
    const percent = Math.floor((progress * 100 / max) + (nextParentSections.findIndex((e) => e.name === questionnaireStore.questionsStore.currentSection?.name) * 100 / (max * nextParentSections.length)))
    let parentSections = questionnaireStore.questionsStore.parentSections

    return (
        <div className="progress_bar_container">
            {/* <div className="progress_percentage">
                {`${percent}%`}
            </div> */}
            <div className="progress_bar">
                {parentSections.map((e, index) => {
                    const step = 100 / (parentSections.length - 1) * index
                    return (
                        <div key={index} className='progress_part_container'>
                            <div style={{ background: `${step <= percent ? 'hsl(194, 12%, 47%)' : '#d2e4e9'}` }} key={index} className='progress_part'></div>
                            <div style={{ fontWeight: `${step <= percent ? 'bold' : ''}` }} className='progress_part_text'>{e.header} {e.numOfQuestions && `(${e.name==='INFO_CONCLUSION' ? e.numOfQuestions-4 : e.numOfQuestions})`}</div>
                        </div>
                    )
                })}
                <div className="progress_fill" style={{ height: `${percent}%` }} />
            </div>
        </div>
    );
}

export default ProgressBar;