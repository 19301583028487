import { observer } from 'mobx-react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuestionnaire } from '../stores/questionnaire.store';

import GenericPopup from '../components/GenericPopup';

import QuestionnaireTranslation from '../translations/questionnaire.translation';

import "../scss/StopQuestionnairePopup.scss";

const StopQuestionnairePopup: FC = () => {
    const questionnaireStore = useQuestionnaire();
    const { language } = questionnaireStore.userStore;

    const history = useHistory();

    const handleClick = () => {
        questionnaireStore.deleteStorage();
        history.push("");
    }


    return (
        <GenericPopup
            isOpen={questionnaireStore.userStore.questionnaireStopped}
            title={QuestionnaireTranslation.TIME_IS_UP[language]}
        >
            <div className="stop_popup_button_container">
                <button onClick={handleClick}>{QuestionnaireTranslation.RESTART[language]}</button>
            </div>
        </GenericPopup>
    );
}

export default observer(StopQuestionnairePopup);